import React from "react"

import ReactHtmlParser from "react-html-parser"
import { Helmet } from "react-helmet"

const SEO = data => {
  const yoast = data.title.yoast
  return (
    <>
      <Helmet>{ReactHtmlParser(yoast)}</Helmet>
    </>
  )
}

export default SEO
